<template>
  <div id="apipe-cloud-course-page">
    <top-nav-new />
    <div class="apipe-cloud-course-container-madeSolution">
      <div class="pc-banner d-none d-lg-block">
        <div class="container" style="text-align: left">
          <h1 style="margin-bottom: 15px; font-size: 42px; font-weight: 700">
            优制云仓储管理WMS系统
          </h1>
          <p class="tit">
            优制云WMS，针对仓储管理痛点，如流程繁琐、库存不清、效率低下，我们提供全链条仓配一体化<br />解决方案。集成条码、RFID技术，实现物料精准收货、质检、入库、上架。支持出库订单自动拣货<br />、定位分配，库存调拨与即时盘点，确保全局库存可视。亮灯拣货提升作业效率，流程与实物双重<br />追踪，构建精益生产物料管控体系。一体化集成，轻松对接现有系统，助力企业仓储管理升级。
          </p>
        </div>
      </div>

      <div class="mobile-banner d-block d-lg-none">
        <div class="container" style="text-align: left">
          <div class="title">优制云仓储管理WMS系统</div>
          <p class="tit">
            优制云WMS，针对仓储管理痛点，如流程繁琐、库存不清、效率低下，我们提供全链条仓配一体化解决方案。集成条码、RFID技术，实现物料精准收货、质检、入库、上架。支持出库订单自动拣货、定位分配，库存调拨与即时盘点，确保全局库存可视。亮灯拣货提升作业效率，流程与实物双重追踪，构建精益生产物料管控体系。一体化集成，轻松对接现有系统，助力企业仓储管理升级。
          </p>
        </div>
      </div>

      <!-- 行业痛点 -->
      <div class="pc-industry d-none d-lg-block">
        <div class="pro-tit">行业痛点</div>
        <div class="container text-center">
          <div class="row row-cols-1 row-cols-lg-4 g-2 g-lg-3">
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/wms/hangye1.png" alt="" />
                <h6>库存管理混乱</h6>
                <div class="des">
                  <div>库存数据不准确，导致过度库存</div>
                  <div>或库存不足</div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/wms/hangye2.png" alt="" />
                <h6>货物流向不明</h6>
                <div class="des">
                  <div>货物追踪困难，无法及时了解库</div>
                  <div>存状态</div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/wms/hangye3.png" alt="" />
                <h6>空间利用率低</h6>
                <div class="des">
                  <div>仓储空间分配不合理，浪费空间</div>
                  <!-- <div>效率难以提升</div> -->
                </div>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/wms/hangye4.png" alt="" />
                <h6>出入库流程繁琐</h6>
                <div class="des">
                  <div>手工记录出入库信息，流程复</div>
                  <div>杂，容易出错</div>
                </div>
              </div>
            </div>
          </div>

          <div class="row"></div>
        </div>
      </div>
      <div class="mobile-industry d-block d-lg-none">
        <div class="pro-tit">行业痛点</div>
        <div class="container text-center">
          <div class="row row-cols-1 row-cols-lg-4 g-2 g-lg-3">
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/wms/hangye1.png" alt="" />
                <h6>库存管理混乱</h6>
                <div class="des">
                  <div>库存数据不准确，导致过度库存</div>
                  <div>或库存不足</div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/wms/hangye2.png" alt="" />
                <h6>货物流向不明</h6>
                <div class="des">
                  <div>货物追踪困难，无法及时了解库</div>
                  <div>存状态</div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/wms/hangye3.png" alt="" />
                <h6>空间利用率低</h6>
                <div class="des">
                  <div>仓储空间分配不合理，浪费空间</div>
                  <!-- <div>效率难以提升</div> -->
                </div>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/wms/hangye4.png" alt="" />
                <h6>出入库流程繁琐</h6>
                <div class="des">
                  <div>手工记录出入库信息，流程复</div>
                  <div>杂，容易出错</div>
                </div>
              </div>
            </div>
          </div>

          <div class="row"></div>
        </div>
      </div>

      <!-- 解决方案 -->
      <div class="container text-center">
        <div class="pro-tit d-none d-lg-block" style="font-size: 36px">
          解决方案
        </div>
        <div
          class="pro-tit d-block d-lg-none"
          style="font-size: 20px; font-weight: 700; margin-bottom: 20px"
        >
          解决方案
        </div>
        <img
          style="width: 100%"
          src="@/assets/newSite/wms/solution.png"
          alt=""
        />
      </div>

      <!-- 产品能力 -->
      <div class="pc-pro-power d-none d-lg-block">
        <div class="pro-tit" style="font-size: 36px">产品能力</div>
        <div>
          <div class="tabs container text-center">
            <div
              :class="['items', tbx == index ? 'active' : '']"
              v-for="(item, index) in tabListNew"
              :key="index"
              @click="handleTab(index)"
            >
              <img :src="item.img1" />
              <div class="tit">{{ item.tit }}</div>
            </div>
          </div>
          <div class="tab-cot">
            <div class="container text-center">
              <div class="row" v-show="tbx == 0">
                <div class="col-xs-12 col-md-6 lf">
                  <div class="mtit">
                    入库通知单、收货任务、质检任务、上架任务
                  </div>
                  <div class="mcot">
                    <div>
                      负责处理货物从接收到正式入库的全过程。它支持多种入库方式（如采购入库、退货入库、生产入库等），通过扫描条码快速识别和标注货物信息，减少人工错误。同时，该模块还能实时更新库存数据，优化库存布局，提高入库效率与准确性。
                    </div>
                  </div>
                  <div style="text-align: left">
                    <el-button
                      @click="handleTest"
                      type="primary"
                      round
                      class="btn btn-primary btn-sm"
                    >
                      免费试用
                    </el-button>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/wms/c.png"
                    alt=""
                    style="width: 90%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 1">
                <div class="col-xs-12 col-md-6 lf">
                  <div class="mtit">出库单、拣货任务、OQC检验</div>
                  <div class="mcot">
                    <div>
                      出库管理模块负责处理货物出库请求，包括出库单订单处理、拣货、出库等环节。系统根据出库单自动生成拣货任务，确保货物按需求高效出库。同时，提供出库OQC功能，确保货物准确无误地离开仓库
                    </div>
                  </div>
                  <div style="text-align: left">
                    <el-button
                      @click="handleTest"
                      type="primary"
                      round
                      class="btn btn-primary btn-sm"
                    >
                      免费试用
                    </el-button>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/wms/c.png"
                    alt=""
                    style="width: 90%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 2">
                <div class="col-xs-12 col-md-6 lf">
                  <div class="mtit">调拨管理、盘点管理、移库单</div>
                  <div class="mcot">
                    <div>
                      库内管理模块负责仓库内部的日常运营管理。它涵盖库存盘点、库位调整、货物移库、调拨等功能。通过实时监控库存状态与库位变动，实现库存动态平衡与优化
                    </div>
                  </div>
                  <div style="text-align: left">
                    <el-button
                      @click="handleTest"
                      type="primary"
                      round
                      class="btn btn-primary btn-sm"
                    >
                      免费试用
                    </el-button>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/wms/c.png"
                    alt=""
                    style="width: 90%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 3">
                <div class="col-xs-12 col-md-6 lf">
                  <div class="mtit">条码补印、条码拆分、条码合并</div>
                  <div class="mcot">
                    <div>
                      条码管理模块它负责条码的补印、拆分与合并，确保每一件货物都有唯一的身份标识。通过条码，实现货物从入库到出库的全链条追踪与管理，提高数据录入的准确性与效率
                    </div>
                  </div>
                  <div style="text-align: left">
                    <el-button
                      @click="handleTest"
                      type="primary"
                      round
                      class="btn btn-primary btn-sm"
                    >
                      免费试用
                    </el-button>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/wms/c.png"
                    alt=""
                    style="width: 90%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 4">
                <div class="col-xs-12 col-md-6 lf">
                  <div class="mtit">
                    库存报表、库龄报表、条码追溯、入库明细、出库明细等
                  </div>
                  <div class="mcot">
                    <div>
                      报表管理模块提供丰富的报表与数据分析功能，帮助管理层全面了解仓库运营状况。包括库存报表、出入库明细、库龄分析等多种报表类型。为决策制定提供有力支持
                    </div>
                  </div>
                  <div style="text-align: left">
                    <el-button
                      @click="handleTest"
                      type="primary"
                      round
                      class="btn btn-primary btn-sm"
                    >
                      免费试用
                    </el-button>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/wms/c.png"
                    alt=""
                    style="width: 90%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mobile-pro-power d-block d-lg-none">
        <h4 class="pro-tit">产品能力</h4>
        <div class="">
          <div class="tabs">
            <div
              :class="['items', idx == index ? 'active' : '']"
              v-for="(item, index) in tabListNew"
              :key="index"
              @click="handleTab(index)"
            >
              <img :src="item.img1" />
              <div class="tit">{{ item.tit }}</div>
            </div>
          </div>
          <div class="tab-cot">
            <div class="container text-center">
              <div class="row" v-show="idx == 0">
                <div class="col-xs-12 col-md-6 lf">
                  <h4 style="text-align: left">生产BOM、文件管理</h4>
                  <div
                    style="
                      text-align: left;
                      margin: 10px 0 10px 0;
                      color: #333333;
                    "
                  >
                    <div>工单生产BOM和对应资料的管理</div>
                    <div>便于管理工单资料核对生产BOM信息</div>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/mes/a.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
                <div style="text-align: left; margin-top: 10px">
                  <el-button @click="handleTest" type="primary" round>
                    免费试用
                  </el-button>
                </div>
              </div>

              <div class="row" v-show="idx == 1">
                <div class="col-xs-12 col-md-6 lf">
                  <!-- <h6>数字化办公平台——伟班</h6> -->
                  <p>
                    无纸化作业，降低企业成本 使用移动IPDA进行检验登记，方便快捷
                    网络传输，减少中间环节 加快现场响应时间，减少等待浪费
                  </p>
                  <div>
                    <!-- <button @click="handleTest" type="button" class="btn btn-primary btn-sm">
                        免费试用
                      </button> -->
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/madeSolution/p2.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
              </div>
              <div class="row" v-show="idx == 2">
                <div class="col-xs-12 col-md-6 lf">
                  <!-- <h6>数字化办公平台——伟班</h6> -->
                  <p>
                    通过出货单、箱号、客户序列号，从后往前查询该产品所经工序、人、机、料、法、测等信息；
                    无纸化作业，降低企业成本 一键追溯，方便快捷
                    追溯报告网络传输，减少中间环节
                    对追溯不良品，现场及时响应，减少等待浪费
                    根据客户情况，可实现批次级或物料级追溯，方便问题定位
                  </p>
                  <div>
                    <!-- <button @click="handleTest" type="button" class="btn btn-primary btn-sm">
                        免费试用
                      </button> -->
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/madeSolution/p3.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
              </div>
              <div class="row" v-show="idx == 3">
                <div class="col-xs-12 col-md-6 lf">
                  <!-- <h6>数字化办公平台——伟班</h6> -->
                  <p>
                    自动记录生产过程中的各项数据，节省人工
                    快速统计分析，并以图表形式展示，标准客观，堵截人工统计出错
                    数据展示实时，方便领导决策
                  </p>
                  <div>
                    <!-- <button @click="handleTest" type="button" class="btn btn-primary btn-sm">
                        免费试用
                      </button> -->
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/madeSolution/p4.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 产品优势 -->
      <div class="pc-scene d-none d-lg-block">
        <div class="pro-tit d-none d-lg-block">产品优势</div>
        <div class="container text-center">
          <div class="row row-cols-1 row-cols-lg-4 g-2 g-lg-3">
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/wms/ad1.png" alt="" />
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/wms/ad2.png" alt="" />
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/wms/ad3.png" alt="" />
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/wms/ad4.png" alt="" />
              </div>
            </div>
          </div>

          <div class="row"></div>
        </div>
      </div>
      <!-- 产品优势 -->
      <div class="mobile-scene d-block d-lg-none">
        <div
          class="pro-tit d-block d-lg-none"
          style="font-size: 20px; font-weight: 700; margin-bottom: 20px"
        >
          产品优势
        </div>
        <div class="container text-center">
          <div class="row row-cols-1 row-cols-lg-4 g-2 g-lg-3">
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/wms/ad1.png" alt="" />
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/wms/ad2.png" alt="" />
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/wms/ad3.png" alt="" />
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/wms/ad4.png" alt="" />
              </div>
            </div>
          </div>

          <div class="row"></div>
        </div>
      </div>

      <!-- 成功案列 -->
      <section
        class="pc-product d-none d-lg-block"
        style="margin: 80px 0 50px 0"
      >
        <div class="container text-center">
          <h1 class="pro-tit" style="margin-bottom: 20px; font-size: 36px">
            成功案例
          </h1>
          <div class="row align-items-start">
            <div
              :class="['col', idx == index ? 'acticv' : '']"
              @click="handleSwitch(index)"
              v-for="(item, index) in tabs"
              :key="index"
            >
              <span>{{ item }}</span>
            </div>
          </div>
        </div>
        <div class="pro-wrap">
          <div
            class="container animate__animated animate__fadeInLeftBig"
            v-show="idx == 0"
          >
            <div class="content" style="display: flex;padding:20px 0 ;">
              <div class="wrap">
                <h6 style="font-size: 35px">
                  {{caseA.contentTitle}}
                </h6>
                <p class="p-content">
                  {{caseA.contentDescription}}
                </p>
                <div>
                  <el-button
                type="primary" size="mini" round
                  @click="
                    () => {
                      this.openNewWindow(`/caseDetail?id=${caseA.id}&tit=${caseA.contentTitle}`);
                    }
                  "
                  class="btn btn-primary btn-sm " 
                >
                  查看详情
                </el-button>
                </div>
              </div>
              <div style="flex: 40%; text-align: center">
                <img :src="baseImgUrl + caseA.contentImgUrl" width="640px" height="360px"/>
              </div>
            </div>
          </div>
          <div
            class="container animate__animated animate__fadeInLeftBig"
            v-show="idx == 1"
          >
            <div class="content" style="display: flex;padding:20px 0 ;">
              <div class="wrap" style="flex: 60%">
                <h6 style="font-size:35px;">{{caseB.contentTitle}}</h6>
                <p class="p-content">
                  {{caseB.contentDescription}}
                </p>
                <div>
                  <el-button
                type="primary" size="mini" round
                  @click="
                    () => {
                      this.openNewWindow(`/caseDetail?id=${caseB.id}&tit=${caseB.contentTitle}`);
                    }
                  "
                  class="btn btn-primary btn-sm " 
                >
                  查看详情
                </el-button>
                </div>
              </div>
              <div style="flex: 40%; text-align: center">
                <img :src="baseImgUrl + caseB.contentImgUrl" width="640px" height="360px"/>
              </div>
            </div>
          </div>
          <div
            class="container animate__animated animate__fadeInRightBig"
            v-show="idx == 2"
          >
            <div class="content" style="display: flex;padding:20px 0 ;">
              <div class="wrap" style="flex: 60%">
                <h6  style="font-size:35px;">{{caseC.contentTitle}}</h6>
                <p class="p-content">
                  {{caseC.contentDescription}}
                </p>
                <div>
                  <el-button
                type="primary" size="mini" round
                  @click="
                    () => {
                      this.openNewWindow(`/caseDetail?id=${caseC.id}&tit=${caseC.contentTitle}`);
                    }
                  "
                  class="btn btn-primary btn-sm" 
                >
                  查看详情
                </el-button>
                </div>
              </div>
              <div style="flex: 40%; text-align: center">
                <img :src="baseImgUrl + caseC.contentImgUrl" width="640px" height="360px"/>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        class="mobile-product d-block d-lg-none"
        style="margin-top: 40px"
      >
        <div class="container text-center">
          <h3 class="pro-tit">成功案例</h3>
          <div class="row align-items-start">
            <div
              :class="['col', idx == index ? 'acticv' : '']"
              @click="handleSwitch(index)"
              v-for="(item, index) in tabs"
              :key="index"
            >
              <span>{{ item }}</span>
            </div>
          </div>
        </div>
        <div class="pro-wrap">
          <div
            class="container animate__animated animate__fadeInLeftBig"
            v-show="idx == 0"
          >
            <div class="content">
              <div style="flex: 60%">
                <h6 style="font-weight: 700; margin-bottom: 1rem">
                  优制云助力华瀚管道实现智能化生产
                </h6>
                <p class="p-content">
                  优制云助力华瀚管道实现智能化生产，通过智能制造MES系统的深度应用，实现了生产流程的精细化管理和智能化控制。系统优化了生产计划、实时监控设备状态、自动采集生产数据，有效提升了生产透明度，为华瀚管道带来了显著的经济效益和市场竞争优势。
                </p>
              </div>
              <div style="text-align: center; margin-bottom: 20px">
                <img src="@/assets/newSite/home/cases.png" />
              </div>
              <div>
                <el-button
                  @click="
                    () => {
                      this.openNewWindow('/smartMade');
                    }
                  "
                  round
                  type="button"
                  class="btn btn-primary btn-sm"
                >
                  了解更多
                </el-button>
              </div>
            </div>
          </div>
          <div
            class="container animate__animated animate__fadeInLeftBig"
            v-show="idx == 1"
          >
            <div class="content">
              <div style="flex: 60%">
                <h6 style="font-weight: bold; margin-bottom: 0.5rem">
                  智能办公
                </h6>
                <p class="p-content">
                  智能化：借助先进的技术和设备，智能办公能够实现智能化决策、智能化处理、智能化控制等，提高工作效率和质量。
                  高效性：能够减少繁琐的手动操作，简化工作流程，提高工作效率。
                  协同性：能够实现团队协作的高效协同，提高团队协作效率。
                  安全性：具备较高的安全性，能够保护企业和员工的隐私和数据安全。
                  移动性：支持移动办公，IM即时聊天，员工可以随时随地进行办公，提高工作效率和灵活性。
                  云端化：基于云计算技术实现，能够实现数据的云端存储、备份和恢复等功能，提高数据的安全性和可靠性。
                  数据分析：能够对各种数据进行分析和挖掘，为企业提供更全面的数据支持和决策参考。
                </p>
                <div class="btns" style="">
                  <el-tag type="info" style="">伟班（OA）</el-tag>
                </div>
                <div>
                  <button
                    @click="
                      () => {
                        this.openNewWindow('/smartOffice');
                      }
                    "
                    type="button"
                    class="btn btn-primary btn-sm"
                  >
                    了解更多
                  </button>
                </div>
              </div>
              <div style="flex: 40%; text-align: center">
                <img src="@/assets/newSite/home/s2.png" />
              </div>
            </div>
          </div>
          <div
            class="container animate__animated animate__fadeInRightBig"
            v-show="idx == 2"
          >
            <div class="content">
              <div style="flex: 60%">
                <h6 style="font-weight: bold; margin-bottom: 0.5rem">
                  智慧园区
                </h6>
                <p class="p-content">
                  数据可视化及应用：园区通过人脸、手机一键开门，通行无卡化、安全便捷，员工app自主登记邀约，访客到访自主无感通行…
                  能耗管理：园区通过人脸、手机一键开门，通行无卡化、安全便捷，员工app自主登记邀约，访客到访自主无感通行，访客通行权限…
                  安全管理：视频上云助力园区远程巡视，降本增效；制定园区安全检查标准，通过视频巡更+图片巡更方式进行园区日常巡逻，提高管理效率…
                  设备运维：融合AIoT技术，实现对设备资产的全生命周期管理，变配电房、给排水房等机电设备智能化实时监测，降低安全隐患…
                  服务体验：园区通过人脸、手机一键开门，通行无卡化、安全便捷，员工app自主登记邀约，访客到访自主无感通行…
                  集成能力：打通园区运营系统、工单等系统，实现智能化数据与业务系统的流程自动化、全场景闭环，可将智能化应用集成至现有员工app…
                </p>
                <div class="btns">
                  <el-tag type="info" style="">智慧园区</el-tag>
                </div>
                <div>
                  <button
                    @click="
                      () => {
                        this.openNewWindow('/smartPark');
                      }
                    "
                    type="button"
                    class="btn btn-primary btn-sm"
                  >
                    免费试用
                  </button>
                </div>
              </div>
              <div style="flex: 40%; text-align: center">
                <img src="@/assets/newSite/home/s3.png" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <right-nav-new class="d-none d-lg-block" />
    </div>

    <NewFooter />
    <MobileFooter class="d-block d-lg-none" />
  </div>
</template>
          
  <script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import SwiperCore, { Autoplay } from "swiper";
SwiperCore.use([Autoplay]);
import Qs from "qs";
import { Newslist } from "@/api/fetch";

export default {
  name: "hardware",
  data() {
    return {
      tabs: ["优管网", "数研时空", "润玉空间"],
      activeName: "1",
      idx: 0,
      tbx: 0,
      ids: -1,
      tabListNew: [
        {
          img1: require("@/assets/newSite/wms/p1.png"),
          tit: "入库管理",
        },
        {
          img1: require("@/assets/newSite/wms/p2.png"),
          tit: "出库管理",
        },
        {
          img1: require("@/assets/newSite/wms/p3.png"),
          tit: "库内管理",
        },
        {
          img1: require("@/assets/newSite/wms/p4.png"),
          tit: "条码管理",
        },
        {
          img1: require("@/assets/newSite/wms/p5.png"),
          tit: "报表模块",
        }
      ],

      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 30,
        freeMode: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      baseImgUrl:'https://admin.veiban.com',
      caseList:[],
      caseA:{},
      caseB:{},
      caseC:{},
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  created() {
    this.getCase();
  },
  methods: {
   //成功案例
   getCase() {
      Newslist(
        Qs.stringify({ categoryId: '1839482945283117058', pageSize: 10, pageNo: 1,productCode:'wms' })
      ).then((res) => {
        if (res.data.rows.length) {
            this.caseA = res.data.rows [0]
            this.caseB = res.data.rows [1]
            this.caseC = res.data.rows [2]
            this.caseList =  res.data.rows
        }
      });
    },
    openNewWindow(path) {
      const routeData = this.$router.resolve({path,id:'123456'});
      window.open(routeData.href, "_blank");
    },
    handleSwitch(idx) {
      this.idx = idx;
    },
    handleTest() {
      window.open("https://apipecloud.veiban.com/login");
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },

    handleTab(index) {
      this.tbx = index
      // this.idx = index;
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll, false);
  },
};
</script>
          
  <style lang="less" scoped>
.apipe-cloud-course-container-madeSolution {
  .pc-banner {
    height: 600px;
    background-image: url("../../assets/newSite/wms/banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    .container {
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .tit {
        font-size: 18px;
        margin: 4px 0;
      }
      .exp {
        margin-top: 2rem;
        font-size: 14px;
        border: 1px solid white;
        display: inline-block;
        width: 100px;
        padding: 2px 0;
        text-align: center;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .mobile-banner {
    height: 300px;
    width: 100%;
    background-image: url("../../assets/newSite/wms/banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    .container {
      color: white;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .title {
        font-size: 32px;
        text-align: left;
        color: white;
        font-family: Source Han Sans CN;
        font-weight: 200;
        margin-left: 0;
      }
      .tit {
        font-size: 14px;
        margin-top: 15px;
      }
      .exp {
        margin-top: 0.5rem;
        font-size: 12px;
        border: 1px solid white;
        display: inline-block;
        width: 80px;
        text-align: center;
      }
    }
  }

  .pc-pro-power {
    margin: 50px 0;
    .pro-tit {
      margin-bottom: 30px;
    }
    .tabs {
      display: flex;
      .items {
        flex: 25%;
        // height: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        .tit {
          padding: 20px 0 16px 0;
          font-size: 18px;
          font-weight: 700;
        }
        &:hover {
          cursor: pointer;
        }
        img {
          width: 36px;
          height: 36px;
        }
      }
      .active {
        .tit {
          border-bottom: 4px solid rgba(20, 132, 255, 1);
        }
      }
    }
    .tab-cot {
      background: #f6faff;
      padding: 20px;
      .row {
        min-height: 300px;
        padding: 20px 100px;
        .mtit {
          text-align: left;
          font-size: 24px;
          font-weight: 500;
        }
        .mcot {
          text-align: left;
          margin: 20px 0 30px 0;
          color: #333333;
        }
        .lf {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          justify-content: center;
          padding: 10px;
          .btn {
            width: 120px;
            background: #1484ff;
            &:hover {
              background: #66b1ff;
            }
          }
        }
        p {
          color: #666666;
          font-size: 14px;
        }
      }
    }
  }
  .mobile-pro-power {
    margin: 50px 0;
    .pro-tit {
      margin-bottom: 30px;
      font-size: 20px;
      color: #333;
      font-weight: 700;
    }
    .tabs {
      display: flex;
      width: 375px;
      overflow-x: scroll;
      overflow-y: hidden;
      margin: 0 auto;
      .items {
        width: 33%;
        flex-shrink: 0;
        height: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        &:hover {
          cursor: pointer;
        }
        .tit {
          padding: 20px 0 16px 0;
        }
        img {
          width: 60px;
          height: 60px;
        }
      }
      .active {
        .tit {
          border-bottom: 4px solid rgba(20, 132, 255, 1);
        }
      }
    }
    .tab-cot {
      background: #f6faff;
      padding: 20px;
      .row {
        min-height: 300px;
        padding: 10px;
        .lf {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          justify-content: center;
          padding: 10px;
          .btn {
            width: 100px;
          }
        }
        p {
          color: #666666;
          font-size: 14px;
        }
      }
    }
  }
  .pc-scene {
    margin: 80px 0;
    .pro-tit {
      margin-bottom: 30px;
      font-size: 36px;
    }
    .col {
      .p-3 {
        min-height: 200px;
        padding: 0 !important;
      }
    }
    img {
      width: 100%;
    }
    h6 {
      font-size: 16px;
      color: rgba(51, 51, 51, 1);
    }
  }
  .mobile-scene {
    margin: 0 0;
    .pro-tit {
      margin-bottom: 30px;
      font-size: 36px;
    }
    .col {
      .p-3 {
        min-height: 200px;
        padding: 5px !important;
      }
    }
    img {
      width: 100%;
      height: 320px;
    }
    h6 {
      font-size: 16px;
      color: rgba(51, 51, 51, 1);
    }
  }
  .pc-industry {
    margin: 80px 0;
    .pro-tit {
      margin-bottom: 40px;
      font-size: 36px;
    }
    .col {
      .p-3 {
        background: #f6faff;
        height: 240px;
        width: 300px;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
      }
    }
    img {
      width: 100px;
      height: 100px;
    }
    h6 {
      margin-top: 0.5rem;
      font-size: 16px;
      font-weight: 700;
      color: rgba(51, 51, 51, 1);
    }
    .des {
      color: #333333;
      font-size: 14px;
    }
  }
  .mobile-industry {
    margin: 40px 0;
    .pro-tit {
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: 700;
      color: #333;
    }
    .col {
      .p-3 {
        background: #f6faff;
        height: 240px;
        margin: 20px;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
      }
    }
    img {
      width: 100px;
      height: 100px;
    }
    h6 {
      margin-top: 0.5rem;
      font-size: 16px;
      font-weight: 500;
      color: rgba(51, 51, 51, 1);
    }
    .des {
      color: #333333;
      font-size: 14px;
    }
  }
  .pc-product {
    .acticv {
      span {
        height: 4px;
        border-bottom: 4px solid #1484ff;
        color: #1484ff;
      }
    }
    .col {
      cursor: pointer;
      span {
        display: inline-block;
        height: 40px;
      }
    }
    .pro-wrap {
      background: #f6faff;
      text-align: left;
      .container {
        display: flex;

        .cot {
          background: url("../../assets/newSite/home/pc.png");
          background-repeat: no-repeat;
          background-size: cover;
          height: 702px;
          width: 100%;
        }
        img {
          // height: 16rem;
        }
        .content {
          margin: 40px 0;
          width: 100%;
          .wrap {
            flex: 60%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            p {
              font-family: Source Han Sans CN;
              font-weight: 200;
              color: #333333;
              width: 540px;
              margin-bottom: 20px;
              margin-top: 12px;
              font-size: 18px;
            }
          }
          .btn {
            width: 120px;
            background: #1484ff;
            height: 36px;
          }
        }
      }
    }
  }
  .mobile-product {
    .acticv {
      span {
        height: 4px;
        border-bottom: 4px solid #1484ff;
        color: #1484ff;
      }
    }
    .col {
      padding: 1rem 0;
      cursor: pointer;
      span {
        display: inline-block;
        height: 40px;
      }
    }
    .pro-tit {
      font-family: Source Han Sans CN;
      font-weight: 700;
      color: #333333;
      font-size: 20px;
    }
    .pro-wrap {
      text-align: left;
      .container {
        display: flex;
        .content {
          padding: 1rem 1.5rem 1.5rem 1.5rem;
          background: white;
          width: 100%;
          img {
            width: 100%;
            // height: 20rem;
          }
          .p-content {
            color: #666;
            font-size: 0.8rem;
            font-family: "思源黑体";
            line-height: 1.6rem;
          }
          .btn {
            width: 120px;
            background: #1484ff;
            height: 36px;
            color: white;
          }
        }
      }
    }
  }

 

 
}
</style>
          